.navbar-toggler{
    margin-right: 4px;
}

.nav-item, .nav-link{
    margin-left: 4px;
    color:rgb(255, 255, 255)
}


.nav-item:hover, .nav-link:hover{
  margin-left: 4px;
  color:rgb(219, 219, 219)
}


.nav-link:focus{
  color: rgb(97, 97, 97);
  font-weight: bold ;
}

.navbar {
  background-color: #c2c2c2;
}
.mat-button-wrapper {
  color: white;
}

.top-left-part {
    font-size: 0;
}



.btn-light{
  background-color: rgb(126, 126, 126, 0.4)!important ;
}

.btn-light:hover{
  background-color: rgb(209, 209, 209)!important ;
}


.btn-outline:disabled
{
  border-color: #d6d6d6;
  color:  #d6d6d6;
}

.btn-outline
{
  border-color: #929292;
  color:  #929292;
}

html, body {
  background: rgb(241,241,242);
  background: radial-gradient(circle, rgba(241,241,242,1) 0%, rgba(255,255,255,1) 100%);
  height: 100%;
}



footer {
  background-color: #c2c2c2;
  margin-top: 20px;
}

.footer-text {
color:rgb(255, 255, 255); 
font-size: 12px;
}


/* .mouse {
  font-size: 12px; 
  cursor: pointer;
} */

.mouse:hover {
  font-size: 12px; 
  color: rgb(219, 219, 219); 
  cursor: pointer;
}


.logo {
  cursor: pointer;
   height:60px;
}

.login-footer{
font-size: 12px;
}

.mouse-login {
  font-size: 12px; 
  color: rgb(158, 158, 158); 
  cursor: pointer;
}


.mouse-login:hover {
  font-size: 12px; 
  cursor: pointer;
}

.form-control:focus {
  border-color: #a7a7a7;
}

.contactButtonClose {
  border-radius: 1rem 0rem;
  max-width: 100px;
  width: 64px;
}

.contactButtonOpen {
  border-radius: 1rem 1rem 0rem 0rem;
  width: 300px;
  z-index: 1000px;
}


.form-control-sm{
  min-width: 300px;
}

a{
  color: black;
}